<template>
    <div id="app">
        <div class="body fixed mobile">
            <!-- class: fixed, mobile -->
            <!-- Loading Spinner -->
            <!-- <div class="spinner"><div><div></div></div></div> -->
            <!-- Header -->
            <section class="header point_history">
                <a
                    class="icon iconButton third"
                    @click.prevent="$router.push('/home')"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    ></font-awesome-icon>
                </a>
                <span class="fL">我的紅利桃子</span>
                <div class="icon iconButton"></div>
            </section>
            <!-- Main -->
            <section class="main point_history">
                <!-- Tabs -->
                <div class="tabs line fM rounded">
                    <a
                        class="tab flexV width center"
                        @click.prevent="$router.push('/record')"
                    >
                        <span class="fS">紅利桃子</span>
                        <div class="flexH">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fM bold">{{
                                displayPointBalance
                            }}</span>
                        </div>
                    </a>
                    <div class="lineV"></div>
                    <a class="tab flexV width center active" @click.prevent>
                        <span class="fS"
                            >{{ displayLastestEndDateOfPoints }}到期</span
                        >
                        <div class="flexH">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fM bold">{{
                                displayLastestEndOfPoints
                            }}</span>
                        </div>
                    </a>
                </div>
                <!-- Filter -->
                <div class="filter flexH width">
                    <div class="flexH width middle">
                        <div class="dropdown rounded">
                            <select
                                name="type"
                                class="fM rounded"
                                v-model="filterbyTime"
                                @change="filterByTimeHandler"
                            >
                                <option value="tdesc">新到舊</option>
                                <option value="tasc">舊到新</option>
                                <option value="pdesc">點數大到小</option>
                                <option value="pasc">點數小到大</option>
                            </select>
                            <div class="arrow third">
                                <font-awesome-icon
                                    icon="fas fa-caret-down"
                                    size="xs"
                                ></font-awesome-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 紀錄 -->
                <div class="records flexV width padding">
                    <div
                        class="record flexH width between margin padding"
                        v-for="(pointPeriod, index) in displayPointPeriods"
                        :key="'point' + index"
                    >
                        <span class="fL"
                            >{{ tsToDate(pointPeriod.endTs) }}到期</span
                        >
                        <div class="flexH">
                            <div class="img">
                                <img src="@/assets/icon/point.svg" />
                            </div>
                            <span class="fL bold primary">{{
                                pointPeriod.pointBalance
                            }}</span>
                        </div>
                    </div>
                    <div class="lineH"></div>
                </div>
            </section>
            <section class="footer point_history">
                <a
                    class="button rounded"
                    @click.prevent="$router.push('/scanner')"
                >
                    <div class="icon">
                        <img src="@/assets/icon/scanner.svg" />
                    </div>
                    <span class="fM">使用紅利點數</span>
                </a>
            </section>
        </div>
    </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState, mapGetters } from "vuex";

export default {
    name: "PointList",
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            points: [
                {
                    pointBalance: 0,
                    pointPeriods: []
                }
            ],
            filterbyTime: "tdesc"
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    created() {
        this.getUserPoints()
            .then((res) => {
                this.$store.commit("updateUserPoints", res);
                this.points = JSON.parse(JSON.stringify(this.userPoints));
                console.log("this.points: ", this.points);
            })
            .catch((err) => {
                console.log("err: ", err);
            });
    },
    mounted() {},
    computed: {
        ...mapState(["user"]),
        ...mapGetters({
            userPoints: "getTyUserPoints"
        }),
        displayLastestEndDateOfPoints() {
            return this.lastestEndOfPoint ? this.tsToDate(this.lastestEndOfPoint.endTs) : "";
            // if (
            //     this.points.pointPeriods &&
            //     this.points.pointPeriods.length > 0
            // ) {
            //     return this.tsToDate(this.points.pointPeriods[0].endTs);
            // } else {
            //     return "";
            // }
        },
        displayLastestEndOfPoints() {
            return this.lastestEndOfPoint ? this.lastestEndOfPoint.pointBalance : 0;
            // if (
            //     this.points.pointPeriods &&
            //     this.points.pointPeriods.length > 0
            // ) {
            //     return this.points.pointPeriods[0].pointBalance;
            // } else {
            //     return 0;
            // }
        },
        displayPointBalance() {
            return this.userPoints && this.userPoints.pointBalance
                ? this.userPoints.pointBalance
                : 0;
        },
        displayPointPeriods() {
            return this.points && this.points.pointPeriods
                ? this.points.pointPeriods
                : [];
        },
        lastestEndOfPoint() {
            if (
                this.points &&
                this.points.pointPeriods &&
                this.points.pointPeriods.length > 0
            ) {
                let sorted = [...this.points.pointPeriods];
                sorted.sort(function(a, b) {
                    return a.endTs - b.endTs;
                })
                const latestEndOfPoint = sorted.find(p => p.endTs >= moment().unix());
                return latestEndOfPoint;
            }
            return null;
        }
    },
    methods: {
        pointClass(point) {
            return [
                "fL",
                "bold",
                { error: point < 0 },
                { success: point >= 0 }
            ];
        },
        displaySignOfPoint(point) {
            return point >= 0 ? "+" : "-";
        },
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        dummyUserPoints() {
            let result = [
                {
                    merchantId: "tycard2022",
                    pointCode: "1",
                    pointName: "桃園紅桃",
                    pointBalance: 85,
                    pointPeriods: [
                        {
                            startTs: "1649520000",
                            endTs: "1655654399",
                            periodId: "P:2",
                            pointBalance: 85,
                            pointName: "桃園紅桃",
                            pointIssuanceId: 1
                        },
                        {
                            startTs: "1649520000",
                            endTs: "1655654397",
                            periodId: "P:2",
                            pointBalance: 84,
                            pointName: "桃園紅桃",
                            pointIssuanceId: 1
                        },
                        {
                            startTs: "1649520000",
                            endTs: "1655654398",
                            periodId: "P:2",
                            pointBalance: 83,
                            pointName: "桃園紅桃",
                            pointIssuanceId: 1
                        }
                    ]
                }
            ];
            return new Promise((resolve) => {
                resolve({data: result});
            });
        },
        callUserPointsAPI() {
            var config = {
                method: "get",
                url: `${this.apiHost}/points/v1/merchants/${this.merchantId}/members/${this.user.userId}/points`,
                headers: {}
            };

            return this.$http(config)
        },
        getUserPoints() {
            // return this.dummyUserPoints()
            return this.callUserPointsAPI()
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        filterByTimeHandler() {
            function tdesc(b, a) {
                return a.endTs - b.endTs;
            }
            function tasc(a, b) {
                return a.endTs - b.endTs;
            }
            function pdesc(b, a) {
                return a.pointBalance - b.pointBalance;
            }
            function pasc(a, b) {
                return a.pointBalance - b.pointBalance;
            }
            switch (this.filterbyTime) {
                case "tdesc":
                    this.points.pointPeriods.sort(tdesc);
                    break;
                case "tasc":
                    this.points.pointPeriods.sort(tasc);
                    break;
                case "pdesc":
                    this.points.pointPeriods.sort(pdesc);
                    break;
                case "pasc":
                    this.points.pointPeriods.sort(pasc);
                    break;
                default:
                    break;
            }
        }
    }
};
</script>
